@mixin mice {
  @include base-font-family;
  color: #000;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-feature-settings: "palt" 1;
  letter-spacing: normal;
  font-size: 14px;
  line-height: 2;
  padding-left: 40px!important;
  padding-right: 40px!important;
  @media #{$maxM} {
    line-height: 1.8;
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
  p {
    color: #000;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 2;
    @media #{$maxM} {
      line-height: 1.8;
    }
  }
  h2 , h3 , h4 , h5 , h6 {
    font-size: 1.1em;
  }
  h1 , h2 , h3 , h4 , h5 , h6 {
    line-height: 1.8;
  }
  h2 {
    border-left: 5px solid $lightblue;
    font-size: 16px;
    font-weight: 600;
    padding-left: 16px;
    @media #{$maxM} {
      font-size: 15px;
    }
  }
  h3 {
    font-size: 16px;
    @media #{$maxM} {
      font-size: 15px;
    }
  }
  h4 , h5 , h6 {
    font-weight: 600;
  }
  a {
    color: #000;
    text-decoration: underline;
    word-break: break-all;
    @media #{$minM} {
      &:hover {
        text-decoration: none;
      }
    }
  }
  ul {
    list-style: disc;
    margin-left: 1em;
  }
  ol {
    list-style: decimal;
    margin-left: 1em;
  }
  blockquote {
    position: relative;
    padding: 10px 15px 10px 50px;
    box-sizing: border-box;
    font-style: italic;
    background: rgba(#000, .1);
    color: #000;
    @media #{$maxM} {
      margin: 10px 0;
      padding: 10px 15px 10px 40px;
    }
    &:before{
      display: inline-block;
      position: absolute;
      top: 10px;
      left: -3px;
      content: "“";
      font-family: sans-serif;
      font-size: 80px;
      line-height: 1;
      opacity: .6;
      @media #{$maxM} {
        left: 4px;
        font-size: 50px;
      }
    }
    p {
      padding: 0;
      margin: 10px 0;
    }
    cite {
      display: block;
      text-align: right;
      font-size: 0.9em;
      opacity: .6;
    }
  }
  img {
    display: block;
    margin: 0 auto;
    height: auto;
    max-width: 100%;
    &.aligncenter {
      margin: 0 auto;
    }
    &.alignleft {
      float: left;
    }
    &.alignright {
      float: right;
    }
  }
  iframe {
    width: 100%;
  }
  table {
    th, td {
      border: 1px solid $bggray;
      padding: 8px 16px;
    }
    th {
      background: $lightgray;
      color: $darkblue;
    }
  }
  dl {
    dt {
      font-weight: 600;
    }
    dd {
      padding-left: 2em;
    }
  }
}