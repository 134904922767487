@import "../config/mixin";
@import "../config/mice";

#rower {
  color: #333;
  font-size: 16px;
  line-height: 1.7;
  margin: 72px 0 0;
  @media #{$maxM} {
    font-size: 14px;
  }
  p {
    letter-spacing: 0.05em;
  }
  .page {
    &-title {
      color: #01395c;
      margin: 50px 0;
      text-align: center;
      padding: 0 40px;
      @media #{$maxM} {
        padding: 0 24px;
      }
      span.en {
        border-bottom: 1px solid #00aadb;
        color: #00aadb;
        display: inline-block;
        font-size: 60px;
        font-weight: 500;
        letter-spacing: 0.2em;
        line-height: 1.5;
        padding-left: 10px;
        @media #{$maxL} {
          font-size: 36px;
        }
        @media #{$maxM} {
          font-size: 26px;
          font-weight: 600;
        }
      }
    }
    &Title {
      &-ja {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.2em;
        line-height: 1;
        padding-top: 20px;
        @media #{$maxL} {
          font-size: 14px;
          padding-top: 10px;
        }
      }
      &-subtitle {
        font-size: 24px;
        padding-top: 80px;
        @media #{$maxL} {
          padding-top: 40px;
        }
        @media #{$maxM} {
          font-size: 18px;
          text-align: left;
        }
      }
      &-lede {
        font-weight: bold;
        line-height: 2;
        padding-top: 40px;
        strong {
          background: linear-gradient(
            transparent 60%,
            rgba($yellow, 0.6) 60%,
            rgba($yellow, 0.6) 90%,
            transparent 90%
          );
        }
        @media #{$maxM} {
          text-align: left;
        }
      }
    }
  }
}
#page {
  .page-main {
    @include mice;
    padding: 40px 0 120px;
    @media #{$maxM} {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}
